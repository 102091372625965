<template>
    <div>
        <el-dialog
            title="选择商品"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1240px">
            <div class="formContent">
                <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                    <!-- <le-select-local-search label="商品类型" v-model="pageParam.params.goodsType" :options="goodsTypeDic" /> -->
                    <le-input label="商品ID" v-model="pageParam.params.goodsId" />
                    <le-input label="商品名称" v-model="pageParam.params.goodsName" />
                </le-search-form>
                <le-pagview ref="goodsPage" @setData="setTableData" :pageParam="pageParam" :tableRef="$refs.goodsPagelist">
                    <el-table
                        @selection-change="handleSelectionChange"
                        ref="goodsPagelist"
                        :data="tabledata" 
                        style="width:100%;height:100%">
                        <el-table-column type="selection" fixed="left" width="45" ></el-table-column>
                        <el-table-column label="商品ID" prop="typeText" width='80'>
                            <template slot-scope="{ row }">
                                <span>{{ row.id?row.id:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" prop="content">
                            <template slot-scope="{ row }">
                                <span>{{ row.goodsName || '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品类型" prop="content" >
                            <template slot-scope="{ row }">
                                <span>{{ row.goodsType | initDic(goodsTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                selectTableData: [],
                tabledata: [],
                pageParam: {
                    url: this.$Config.apiUrl.getGoodsList,
                    method: "post",
                    params: {
                        name: '',//
                        id: '', //id
                        goodsType: '4'
                    },
                    freshCtrl: 1,
                },
                goodsTypeDic: [],
            };
        },
        props: {
            defaultData: {
                default: ()=>{
                    return []
                }
            }
        },
        created () {
            this.$getDic('goodsType','select').then(res=>{ this.goodsTypeDic = res; })
        },
        methods:{
            // 选中
            handleSelectionChange (selection) {
                this.selectTableData = selection
            },
            defaultCheck (datas) {
                datas.map(item=>{
                    this.tabledata.map((ite,idx)=>{
                        if(item.goodsId == ite.id){
                            this.$refs['goodsPagelist'].toggleRowSelection(this.tabledata[idx],true)
                        }
                    })
                })
            },
            //获取列表
            setTableData(data) {
                this.tabledata = data;
                this.$nextTick(()=>{
                    this.defaultCheck(this.defaultData)
                })
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',//
                    id: '', //id
                    goodsType: '4'
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['goodsPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            submit () {
                this.$emit('selectChange', this.selectTableData)
                this.dialogVisible = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>