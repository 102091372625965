<template>
    <div>
        <el-dialog
            title="选择套餐"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="1200px">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-select-remote-search label="所属项目" v-model="pageParam.params.incomeDistriId" :options="optionsincomeDistri" placeholder="请选择项目(可搜索)" />
                <le-select-remote-search 
                    label="站点"
                    :multiple="false" 
                    v-model="pageParam.params.stationId" 
                    :options="optionsstationIdList" 
                    placeholder="请选择站点(可搜索)" />
            </le-search-form>
            <le-pagview ref="monthcardlistChoose" @setData="setTableData" :pageParam="pageParam">
                <div class="a-flex-rfsfs" style="align-items: stretch;">
                    <el-table 
                        ref="chooseTable"
                        :data="tableData" 
                        :row-key="getRowKey"
                        @selection-change="handleSelectionChange"
                        @current-change="handleCurrentChange"
                        :highlight-current-row="true" 
                        height="55vh"
                        style="width: 100%">
                        <el-table-column type="selection" fixed="left" align="center" width="45" reserve-selection v-if="multiple"></el-table-column>
                        <el-table-column fixed="left" align="center" width="45" v-else>
                            <template slot-scope="{ row }">
                                <el-radio v-model="selectData" :label="row">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id" label="ID" width="80" fixed="left"></el-table-column>
                        <el-table-column prop="name" label="名称" min-width="140">
                            <template slot-scope="{ row }">
                                <span>{{ row.name?row.name:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="incomeDistriId" label="可见模式" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.showMode | initDic(showModelDic) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="userName" label="促销价/划线价" min-width="140">
                            <template slot-scope="{ row }">
                                <span>￥{{ util.numFormat(row.promotionPrice) }}/￥{{ util.numFormat(row.price) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="站点" min-width="240">
                            <template slot-scope="{ row }">
                                <div class="a-flex-rfsc a-flex-wrap">
                                    <span class="stationTag" v-for="(item,index) in row.stationList" :key="index">{{ item.name?item.name:'-' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-card class="box-card a-ml-20">
                        <div slot="header" class="clearfix">
                            <span>已选择表格数据：</span>
                        </div>
                        <div v-if="multiple" class="chooseList">
                            <div class="a-flex-rsbc" v-for="(item,index) in selectData" :key="item.id" style="margin-bottom: 18px;">
                                <span>{{ item.name }}</span>
                                <i class="el-icon-error a-fs-14 a-ml-5 a-c-999" @click="removeChoose(item)"></i>
                            </div>
                            <div class="a-flex-rcc a-c-999" v-if="!selectData || !selectData.length">暂无数据</div>
                        </div>
                        <div v-else class="chooseList">
                            <div class="a-flex-rsbc" v-if="selectData" style="margin-bottom: 18px;">
                                <span>{{ selectData.name }}</span>
                                <i class="el-icon-error a-fs-14 a-ml-5 a-c-999" @click="removeChoose()"></i>
                            </div>
                            <div class="a-flex-rcc a-c-999" v-else>暂无数据</div>
                        </div>
                    </el-card>
                </div>
                
            </le-pagview>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false;">取消</el-button>
                <el-button type="primary"  @click="submit">确定选择</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from '../../../utils/util'
    export default {
        data() {
            return {
                util: util,
                dialogVisible: false,
                tableData: [],
                selectData: null,//选中的列表
                showModelDic: [],//可见模式字典
                pageParam: {
                    url: this.$Config.apiUrl.getMonthcardPage,
                    method: "post",
                    params: {
                        incomeDistriId: '',
                        stationId: '',
                    },
                    freshCtrl: 1,
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsstationIdList: {
                    url: this.$Config.apiUrl.monthStationList,
                    method: "post",
                    params: {
                        incomeDistriId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//站点数据
            };
        },
        props:{
            defaultData:{
                default: [],
            },
            multiple:{
                default: false
            }
        },
        watch:{
            dialogVisible (val) {
                if(val){
                    this.$nextTick(()=>{
                        this.handlerRest()
                        if(!this.multiple){
                            this.selectData = this.defaultData
                        }
                    })
                }else{
                    this.$refs['chooseTable'].clearSelection()
                    this.$refs['chooseTable'].setCurrentRow()
                    this.selectData = null
                }
            },
            'pageParam.params.incomeDistriId' :{
                handler (val) {
                    this.optionsstationIdList.params.incomeDistriId = val
                    this.pageParam.params.stationId = ''
                }
            }
        },
        created () {
            this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
        },
        methods:{
            getRowKey (row) {
                return row.id
            },
            // 多选
            handleSelectionChange (selection) {
                this.selectData = selection
            },
            // 单选
            handleCurrentChange (row) {
                this.selectData = row
            },
            // 设置表格选中状态
            toggleRowSelection (rows) {
                this.$nextTick(() => {
                    this.selectData.map((ite,idx)=>{
                        this.tableData.map((item, index) => {
                            if(ite.id == item.id){
                                this.$refs.chooseTable.toggleRowSelection(item, true);
                            }
                        });
                    })
                    
                }) 
            },
            handlerRest() {
                this.pageParam.params = {
                    incomeDistriId: '',
                    stationId: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['monthcardlistChoose'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //获取列表
            setTableData(data) {
                this.tableData = data;
                if(this.multiple){
                    if(this.defaultData){
                        this.selectData = this.defaultData
                        this.toggleRowSelection()
                    }
                }else{
                    if(this.selectData){
                        let obj = this.tableData.find(res=>{
                            return res.id == this.selectData.id
                        })
                        this.selectData = obj || this.selectData
                    }
                }
            },
            submit () {
                if(this.multiple){
                    if(!this.selectData || !this.selectData.length){
                        this.$message.error('请至少选择一条表格数据')
                        return
                    }
                }else{
                    if(!this.selectData){
                        this.$message.error('请选择一条表格数据')
                        return
                    }
                }
                this.dialogVisible = false
                this.$emit('selectChange',this.selectData)
            },
            removeChoose (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    if(this.multiple){
                        this.$nextTick(()=>{
                            this.selectData.splice(this.selectData.indexOf(datas), 1);
                        })
                        this.$refs['chooseTable'].toggleRowSelection(datas)
                    }else{
                        this.selectData = null
                        this.$refs['chooseTable'].setCurrentRow()
                    }
                })
                .catch(_=>{})
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__body{
        padding: 0px 20px;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
    .chooseList{
        overflow:auto;
        width: 200px;
        height: calc(55vh - 100px);
    }
    .chooseList::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
</style>