<template>
    <div>
        <el-dialog
            title="选择商品"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1000px">
            <div class="a-flex-rfsc a-flex-wrap">
                <le-select-local-search label="商品类型" v-model="goodsType" :clearable='false' :options="optionsGoodsType" />
                <le-select-remote-search 
                    label="站点"
                    large
                    :multiple="false" 
                    v-model="stationId" 
                    :options="optionsstationIdList" 
                    placeholder="请选择站点(可搜索)" />
                <div class="a-flex-rfsc" v-if="tabledata.length">
                    <le-switch label="统一日期" v-model="unifyTime" actext="开启" inactext="" :acValue="1" :inacValue="0"></le-switch>
                    <jd-datetime-range 
                        style="padding: 0"
                        v-if="unifyTime"
                        large
                        :defaultTime="['00:00:00','23:59:59']"
                        valueFormat="yyyy-MM-dd HH:mm:ss"
                        label="" 
                        :minDate.sync="startTime" 
                        :maxDate.sync="endTime" />
                </div>
            </div>
            <el-table
                v-if="tabledata.length"
                @selection-change="handleSelectionChange"
                ref="multipleTable"
                :data="tabledata" 
                :height='tabledata.length?"60vh":""'>
                <el-table-column :selectable='handleSelectable' type="selection" fixed="left" width="45" ></el-table-column>
                <el-table-column label="商品ID" prop="typeText" width='80'>
                    <template slot-scope="{ row }">
                        <span>{{ row.id?row.id:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="content">
                    <template slot-scope="{ row }">
                        <span>{{ row.name || '-' }}</span>
                        <span class="a-c-error">{{ row.activity ? '（已参与活动）' : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="类型" prop="content" width="80">
                    <template slot-scope="{ row }">
                        <span>{{ row.isTempCard | initDic(isTempCardDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="生效日期" prop="orderId">
                    <template slot-scope="{ row }">
                        <jd-datetime-range 
                            :disabled='unifyTime?true:false'
                            style="margin: 10px 0 10px -20px;padding: 0;" 
                            label="" 
                            large
                            :defaultTime="['00:00:00','23:59:59']"
                            valueFormat="yyyy-MM-dd HH:mm:ss"
                            :minDate.sync="row.startTime" 
                            :maxDate.sync="row.endTime" />
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                dialogVisible: false,
                util: util,
                stationId: '',
                goodsType: 1,
                unifyTime: 0,
                types: [1,2,3],
                startTime: '',
                endTime: '',
                tabledata: [],
                optionsstationIdList: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {
                        incomeId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                    pageSize: 500
                },//站点数据
                selectTableData: [],
                optionsGoodsType: [{
                    label: '套餐卡',
                    value: 1
                },{
                    label: '储值卡',
                    value: 2
                },{
                    label: '安心卡',
                    value: 3
                },{
                    label: '优惠券包',
                    value: 4
                }],
                isTempCardDic: [],
                apiUrl: '',
                apiParams: {}
            };
        },
        mounted () {
            this.$getDic('istempcard','select').then(res=>{ this.isTempCardDic = res; })
        },
        props: {
            defaultData: {
                default: ()=>{
                    return []
                }
            }
        },
        computed: {
            unifystartTimeAndEndTime () {
                return this.startTime + this.endTime
            }
        },
        watch: {
            stationId: {
                handler (val) {
                    if(val) {
                        this.getTableData()
                    }
                }
            },
            goodsType: {
                handler (val) {
                    this.getTableData()
                }
            },
            unifyTime: {
                handler (val) {
                    if(val) {
                        this.$nextTick(()=>{
                            if(this.selectTableData.length) {
                                this.startTime = this.selectTableData[0].startTime || ''
                                this.endTime = this.selectTableData[0].endTime || ''
                            }
                            this.selectTableData = this.selectTableData.map(item=>{
                                return {
                                    ...item,
                                    startTime :this.startTime,
                                    endTime: this.endTime
                                }
                            })
                            this.tabledata.map(item=>{
                                item.startTime = this.startTime
                                item.endTime = this.endTime
                            })
                        })
                    }
                }
            },
            unifystartTimeAndEndTime: {
                handler (val) {
                    if(val) {
                        this.$nextTick(()=>{
                            this.selectTableData = this.selectTableData.map(item=>{
                                return {
                                    ...item,
                                    startTime :this.startTime,
                                    endTime: this.endTime
                                }
                            })
                            this.tabledata.map(item=>{
                                item.startTime = this.startTime
                                item.endTime = this.endTime
                            })
                        });
                        
                    }
                }
            }
        },
        methods:{
            getTableData () {
                if(this.goodsType == 1) {
                    if(!this.stationId) {
                        this.tabledata = []
                        return false
                    }
                    this.apiUrl = this.$Config.apiUrl.getMonthcardPage
                    this.apiParams = {
                        types: [1,2,3],
                        stationId: this.stationId,
                        pageSize: 1000,
                        pageNum: 1
                    }
                }else if(this.goodsType == 2) {
                    this.apiUrl = this.$Config.apiUrl.getMonthcardPage
                    this.apiParams = {
                        types: [5],
                        stationId: this.stationId,
                        pageSize: 1000,
                        pageNum: 1
                    }
                }else if(this.goodsType == 3) {
                    this.apiUrl = this.$Config.apiUrl.getMonthcardPage
                    this.apiParams = {
                        types: [6],
                        stationId: this.stationId,
                        pageSize: 1000,
                        pageNum: 1
                    }
                }else if(this.goodsType == 4) {
                    this.apiUrl = this.$Config.apiUrl.getGoodsList
                    this.apiParams = {
                        name: '',//
                        id: '', //id
                        goodsType: '4',
                        pageSize: 1000,
                        pageNum: 1
                    }
                }
                this.$Axios._post({
                    url: this.apiUrl,
                    method: "post",
                    params: {
                        ...this.apiParams
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        let arr = []
                        if(this.goodsType == 4) {
                            arr = res.result.data.list
                        }else {
                            res.result.data.list.map(item=>{
                                item.voList.map(ite=>{
                                    arr.push({
                                        ...ite,
                                        goodsType: 2
                                    })
                                })
                            })
                        }
                        
                        this.tabledata = arr.map(item=>{
                            return {
                                ...item,
                                startTime: this.startTime,
                                endTime: this.endTime,
                            }
                        })
                        this.$nextTick(()=>{
                            this.defaultCheck(this.defaultData)
                            this.tabledata.map(item=>{
                                this.defaultData.map(ite=>{
                                    if(item.id == ite.goodsId || item.goodsId == ite.goodsId) {
                                        item.startTime = ite.startTime
                                        item.endTime = ite.endTime
                                    }
                                })
                            })
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            },
            defaultCheck (datas) {
                datas.map(item=>{
                    this.tabledata.map((ite,idx)=>{
                        if(item.goodsId == ite.id){
                            this.$refs['multipleTable'].toggleRowSelection(this.tabledata[idx],true)
                        }
                    })
                })
            },
            handleSelectable (row,index) {
                // if(row.activity) {
                //     return false
                // }else {
                //     return true
                // }
                return true
            },
            // 套餐规格选中
            handleSelectionChange (selection) {
                this.selectTableData = selection
            },
            submit () {
                let datas = this.selectTableData.map(item=>{
                    return {
                        ...item,
                        goodsId: item.id,
                        goodsName: item.name,
                        startTime: this.unifyTime ? this.startTime : item.startTime,
                        endTime: this.unifyTime ? this.endTime : item.endTime,
                    }
                })
                let startGoods = datas.find(res=>{
                    return !res.startTime
                })
                if(startGoods) {
                    this.$message.error(`请填写所选商品ID为${startGoods.goodsId}的生效时间`)
                    return
                }
                this.$emit('selectChange', datas)
                this.dialogVisible = false
                this.tabledata = []
                this.stationId = ''
                this.goodsType = 1
                this.startTime = ''
                this.endTime = ''
                this.unifyTime = 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        // max-height: 70vh;
        // overflow: auto;
    }
    
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>